import React from 'react';
import { useHistory } from 'react-router';
import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import CardHeader from '@material-ui/core/CardHeader';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import { grey } from '@material-ui/core/colors';
import ConfirmationDialog from '../ConfirmationDialog';
import { getAssetTypeIcon } from '../../../Services/CourseHelper';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  header: {
    padding: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  summary: {
    pointerEvents: 'none',
    '&>*': { margin: theme.spacing(1) },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
  },
  icon: {
    pointerEvents: 'auto',
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  chapter: Object,
  onDeleteChapter: Function,
  urlLink: String,
};

const CardChapterListManage = ({ chapter, onDeleteChapter, urlLink }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState();
  const [openDestroyChapterDiag, setOpenDestroyChapterDiag] = React.useState(false);

  const handleClickConfirmDestroyChapterDiag = () => {
    onDeleteChapter(chapter.id);

    setOpenDestroyChapterDiag(false);
    setAnchorEl(null);
  };

  const handleClickCancelDestroyChapterDiag = () => {
    setOpenDestroyChapterDiag(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Card elevation={2} className={classes.container}>
        <CardHeader
          avatar={getAssetTypeIcon(chapter?.asset_type, grey[600])}
          action={
            <>
              <IconButton
                className={classes.icon}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="actions-menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => history.push(urlLink)}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>{t('EDIT')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setOpenDestroyChapterDiag(true)}>
                  <ListItemIcon>
                    <DeleteForeverIcon />
                  </ListItemIcon>
                  <ListItemText>{t('DELETE')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          }
          title={chapter.title}
          className={classes.header}
          titleTypographyProps={{ style: { textTransform: 'none' } }}
        />
      </Card>
      <ConfirmationDialog
        openDialog={openDestroyChapterDiag}
        onCancel={handleClickCancelDestroyChapterDiag}
        onClickConfirm={handleClickConfirmDestroyChapterDiag}
        dialogTitle={t('APP.COURSES.CHAPTER.DELETE.CONFIRMATION_MESSAGE')}
      />
    </>
  );
};
export default CardChapterListManage;
