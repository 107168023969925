import React from 'react';
import { useTranslation } from 'react-i18next';

import 'moment/locale/fr';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Editor from '../../Posts/EditorWysiwyg';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginFilePoster, FilePondPluginGetFile);
registerPlugin(FilePondPluginFileValidateType);

type Props = {
  course: Object,
  onSubmit: Function,
  onClose: Function,
  visibilities: Array,
};

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  paper: {
    padding: theme.spacing(3),
  },
  container: {
    justifyContent: 'space-around',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  buttons: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  facet: {
    paddingLeft: theme.spacing(2),
  },
  formLabel: {
    paddingLeft: '3px',
    paddingRight: '3px',
    zIndex: '1002',
    backgroundColor: 'white',
  },
  divider: {
    margin: theme.spacing(1, 3),
  },
}));

function CourseForm({ course, onSubmit, onClose, visibilities }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [title, setTitle] = React.useState(course?.title);
  const [description, setDescription] = React.useState(course?.description);
  const [duration, setDuration] = React.useState(course?.duration);
  const [objectives, setObjectives] = React.useState(course?.objectives);
  const [images, setImages] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [visibility, setVisibility] = React.useState(course?.visibility || visibilities[0].value);
  const [state, setState] = React.useState(course?.state || 'pending');

  const states = [
    { label: 'APP.COURSES.STATE.PENDING', value: 'pending' },
    { label: 'APP.COURSES.STATE.PUBLISHED', value: 'published' },
  ];

  const canSubmit = images?.length > 0 && title && duration;
  React.useEffect(() => {
    if (course) {
      const cover = course.cover_image_url;
      const teaser = course.teaser_url;
      if (cover) {
        // current image preview
        setImages([
          {
            source: cover,
            options: {
              type: 'local',
              file: { name: cover, size: 0 },
              metadata: { poster: cover },
            },
          },
        ]);
      }
      if (teaser) {
        setFiles([
          {
            source: teaser,
            options: {
              type: 'local',
              file: { name: teaser, size: 0 },
              metadata: { poster: teaser },
            },
          },
        ]);
      }
    }
  }, [course, setImages, setFiles]);

  const handleImageSelected = fileItems => {
    const im = fileItems.map(fileItem => fileItem.file);
    setImages(im);
  };

  return (
    <Grid container className={classes.layout}>
      <Paper className={classes.paper}>
        <form
          onSubmit={event => {
            onSubmit(
              event,
              {
                slug: course?.slug,
                title,
                description,
                objectives,
                duration,
                visibility,
                state,
              },
              images[0] ? images[0] : null,
              files[0] ? files : null,
            );
            onClose();
          }}
        >
          <Typography variant="h5" component="p" className={classes.title}>
            {course?.slug ? t('APP.COURSES.EDIT') : t('APP.COURSES.CREATE')}
          </Typography>
          <Grid container className={classes.container}>
            <Grid item sm={4} xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.TITLE')}
                </Typography>
                <TextField
                  required
                  name="title"
                  value={title || ''}
                  type="text"
                  onChange={event => setTitle(event.target.value)}
                  variant="outlined"
                  autoFocus
                  fullWidth
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.DURATION')}
                </Typography>
                <TextField
                  required
                  name="duration"
                  value={duration || ''}
                  type="text"
                  onChange={event => setDuration(event.target.value)}
                  variant="outlined"
                  fullWidth
                  inputProps={{ maxLength: 10 }}
                />
              </FormControl>
              <FormControl variant="outlined" component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.VISIBILITY')}
                </Typography>
                <Select
                  value={visibility}
                  onChange={event => setVisibility(event.target.value)}
                  input={<OutlinedInput labelWidth={0} name="role" id="select-visibility" />}
                  fullWidth
                  variant="outlined"
                  disabled={!visibilities || visibilities?.length <= 1}
                >
                  {visibilities.map(v => (
                    <MenuItem key={v.value} value={v.value}>
                      {t(v.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.STATE')}
                </Typography>
                <Select
                  value={state}
                  onChange={event => setState(event.target.value)}
                  input={<OutlinedInput labelWidth={0} name="role" id="select-state" />}
                  fullWidth
                  variant="outlined"
                  disabled={course?.state === 'published'}
                >
                  {states.map(v => (
                    <MenuItem key={v.value} value={v.value}>
                      {t(v.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl component="fieldset" fullWidth required>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.COVER')}
                </Typography>
                <FilePond
                  files={images}
                  allowMultiple={false}
                  maxFiles={1}
                  credits={('', '')}
                  instantUpload={false}
                  acceptedFileTypes={['image/*']}
                  labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
                  onupdatefiles={fileItems => handleImageSelected(fileItems)}
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth required>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.TEASER')}
                </Typography>
                <FilePond
                  files={files}
                  allowMultiple={false}
                  maxFiles={1}
                  credits={('', '')}
                  instantUpload={false}
                  acceptedFileTypes={['video/*']}
                  labelIdle={t('APP.COURSES.FILES.VIDEO.PLACEHOLDER')}
                  onupdatefiles={fileItems => {
                    setFiles(fileItems.map(fileItem => fileItem.file));
                  }}
                />
              </FormControl>
            </Grid>
            <Hidden only={['xs']}>
              <Divider sm={1} orientation="vertical" flexItem className={classes.divider} />
            </Hidden>
            <Grid item sm={7} xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.DESCRIPTION')}
                </Typography>
                <Editor
                  name="description"
                  value={course?.description || ''}
                  onChange={event => setDescription(event.target.value)}
                  options={['inline', 'list', 'link']}
                  editorStyle={{ height: '300px' }}
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.COURSES.TEXT_FIELD.LABEL.OBJECTIVES')}
                </Typography>
                <Editor
                  name="objectives"
                  value={course?.objectives || ''}
                  onChange={event => setObjectives(event.target.value)}
                  options={['inline', 'list', 'link']}
                  editorStyle={{ height: '300px' }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button color="primary" onClick={onClose} variant="outlined">
              {t('CANCEL')}
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              style={{ marginLeft: 16 }}
              disabled={!canSubmit}
            >
              {t('SEND')}
            </Button>
          </div>
        </form>
      </Paper>
    </Grid>
  );
}

export default CourseForm;
