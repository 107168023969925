import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArticleForm from '../../../Components/Shared/Articles/ArticleForm';
import 'moment/locale/fr';
import ArticlesActions from '../../../Redux/ArticlesRedux';
import { HOLDING_VISIBILITIES } from '../../../Services/ArticleHelper';
import { useRouteMatch } from 'react-router';

type Props = {
  article: Object,
};

function HoldingArticleForm({ article }: Props) {
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: orgaSlug } = match.params;
  const isCreating = useSelector(state => state.articles.isFetching.createHoldingArticle);
  const dispatch = useDispatch();
  const getRedirectionLink = () => {
    if (orgaSlug) {
      return `/${holdingSlug}/${orgaSlug}/articles`;
    } else return `/${holdingSlug}/articles`;
  };

  const handleSubmit = (event, articleData, images, attachments) => {
    event.preventDefault();

    if (article) {
      dispatch(
        ArticlesActions.updateHoldingArticleRequest(
          holdingSlug,
          { ...articleData, slug: article.slug },
          images[0],
          attachments,
          getRedirectionLink(),
        ),
      );
    } else {
      dispatch(
        ArticlesActions.createHoldingArticleRequest(
          holdingSlug,
          articleData,
          images[0],
          attachments,
          getRedirectionLink(),
        ),
      );
    }
  };

  return (
    <>
      {holdingSlug && (
        <ArticleForm
          article={article}
          onSubmit={handleSubmit}
          isCreating={isCreating}
          visibilities={HOLDING_VISIBILITIES}
        />
      )}
    </>
  );
}

export default HoldingArticleForm;
