import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import { CardMedia } from '@material-ui/core';

import getExpertiseLevelStyle, { getExpertiseStyle } from '../../../Themes/Expertises';
import { IconShape } from '../../../Themes/Icon';
import { getCdnUrl } from '../../../Services/ImageResizer';

const useStyles = makeStyles(theme => ({
  cardMedia: {
    position: 'relative',
  },
  backgroundColor: {
    opacity: '80%',
    height: 144,
  },
  serviceIcon: {
    position: 'absolute',
    bottom: theme.spacing(0.5),
    left: theme.spacing(0.5),
    backgroundColor: 'White',
    outlineOffset: -1,
    outlineStyle: 'solid',
    width: '45px',
    height: '45px',
    '&>*': {
      width: '45px',
      height: '45px',
    },
  },
  iconOnBackground: {
    color: 'white',
    backgroundColor: 'transparent',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    width: '56px',
    height: '56px',
    '&>*': {
      width: '56px',
      height: '56px',
      color: 'white !important',
    },
  },
  shape: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '65px',
    height: 'auto',
  },
}));

type Props = {
  service: Object,
  isImageWithIcon: Boolean,
};
function ServiceImage({ service, isImageWithIcon }: Props) {
  const classes = useStyles();
  const expertise = service?.expertise;
  const isServiceWithImage = service?.cover_key_basic_dpi;

  return (
    <Grid className={classes.cardMedia}>
      {isServiceWithImage ? (
        <CardMedia
          component="img"
          height="144"
          image={getCdnUrl(service?.cover_key_basic_dpi, 800)}
          alt={service?.title}
          className={classes.media}
        />
      ) : (
        <Grid
          style={{ background: getExpertiseLevelStyle(service?.level?.slug).gradiant }}
          className={classes.backgroundColor}
        />
      )}

      {isImageWithIcon && isServiceWithImage && (
        <>
          <IconShape
            className={classes.shape}
            style={{
              color: getExpertiseStyle(expertise).color,
            }}
          />
          <Avatar
            className={classes.serviceIcon}
            style={{
              color: getExpertiseStyle(expertise).color,
              outlineColor: getExpertiseStyle(expertise).color,
            }}
          >
            {getExpertiseStyle(expertise).icon}
          </Avatar>
        </>
      )}
      {!isServiceWithImage && (
        <Avatar className={classes.iconOnBackground}>{getExpertiseStyle(expertise).icon}</Avatar>
      )}
    </Grid>
  );
}
export default ServiceImage;
