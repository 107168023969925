import React from 'react';
import { useTranslation } from 'react-i18next';

import 'moment/locale/fr';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import Routes from '../../../Routing/Routes';

type Props = {
  chapter: Object,
  onSubmit: Function,
  onClose: Function,
  unitId: Number,
};

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  paper: {
    padding: theme.spacing(3),
  },
  container: {
    justifyContent: 'space-around',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

function ChapterForm({ chapter, onSubmit, onClose, unitId }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [title, setTitle] = React.useState(chapter?.title);
  const [assetType, setAssetType] = React.useState(chapter?.asset_type || 'text');

  const assetTypes = [
    { label: 'APP.COURSES.ASSET.TEXT', value: 'text' },
    { label: 'APP.COURSES.ASSET.VIDEO', value: 'video' },
    { label: 'APP.COURSES.ASSET.IMAGE', value: 'image' },
    { label: 'APP.COURSES.ASSET.AUDIO', value: 'audio' },
    { label: 'APP.COURSES.ASSET.IFRAME', value: 'iframe' },
  ];

  if (Routes.currentLeague().namespace === 'SLA')
    assetTypes.push({ label: 'APP.COURSES.ASSET.QUIZ', value: 'quiz' });

  return (
    <Paper className={classes.paper} elevation={0}>
      <form
        onSubmit={event => {
          onSubmit(event, {
            id: chapter?.id,
            title,
            asset_type: assetType,
            parent_id: unitId,
          });
          onClose();
        }}
      >
        <Typography variant="h5" component="p" className={classes.title}>
          {chapter?.id ? t('APP.COURSES.CHAPTER.EDIT') : t('APP.COURSES.CHAPTER.CREATE')}
        </Typography>
        <Grid container className={classes.container}>
          <FormControl component="fieldset" fullWidth>
            <Typography className={classes.label}>
              {t('APP.COURSES.TEXT_FIELD.LABEL.TITLE')}
            </Typography>
            <TextField
              required
              name="title"
              value={title || ''}
              type="text"
              onChange={event => setTitle(event.target.value)}
              variant="outlined"
              autoFocus
              fullWidth
            />
          </FormControl>
          <FormControl variant="outlined" component="fieldset" fullWidth>
            <Typography className={classes.label}>
              {t('APP.COURSES.TEXT_FIELD.LABEL.ASSET_TYPE')}
            </Typography>
            <Select
              value={assetType}
              onChange={event => setAssetType(event.target.value)}
              input={<OutlinedInput labelWidth={0} name="role" id="select-visibility" />}
              fullWidth
              variant="outlined"
              disabled={chapter?.id}
            >
              {assetTypes.map(v => (
                <MenuItem key={v.value} value={v.value}>
                  {t(v.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <div className={classes.buttons}>
          <Button color="primary" onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button color="primary" type="submit">
            {t('SEND')}
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default ChapterForm;
