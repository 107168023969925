import React from 'react';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Accordion from '@material-ui/core/Accordion';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import ReorderIcon from '@material-ui/icons/Reorder';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@material-ui/core/colors';

import { useTranslation } from 'react-i18next';
import ConfirmationDialog from '../ConfirmationDialog';
import UnitForm from './UnitForm';
import CardChapterListManage from './CardChapterListManage';
import ChapterForm from './ChapterForm';
import ChapterOrderForm from './ChapterOrderForm';

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  summary: {
    pointerEvents: 'none',
    '&>*': { margin: theme.spacing(2) },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: theme.spacing(2),
    },
    '& .MuiAccordion-root:before': {
      opacity: 0,
    },
  },
  icon: {
    pointerEvents: 'auto',
  },
}));

type Props = {
  unit: Object,
  functions: Array,
  urlLink: String,
};

const CardUnitManage = ({ unit, functions, urlLink }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState();
  const [openEditUnitDiag, setOpenEditUnitDiag] = React.useState(false);
  const [openDestroyUnitDiag, setOpenDestroyUnitDiag] = React.useState(false);
  const [showChapterCreationDialog, setShowChapterCreationDialog] = React.useState(false);
  const [openOrderUnitDiag, setOpenOrderUnitDiag] = React.useState(false);

  const handleClickConfirmDestroyUnitDiag = () => {
    functions.onDeleteUnit(unit);

    setOpenDestroyUnitDiag(false);
    setAnchorEl(null);
  };

  const handleClickCancelDestroyUnitDiag = () => {
    setOpenDestroyUnitDiag(false);
    setAnchorEl(null);
  };

  const handleClickConfirmEditUnitDiag = (event, unit) => {
    functions.onEditUnit(event, unit);
    setAnchorEl(null);
  };

  const handleClickCancelEditUnitDiag = () => {
    setOpenEditUnitDiag(false);
    setAnchorEl(null);
  };

  const handleClickOpenOrderUnitDiag = () => {
    setOpenOrderUnitDiag(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Accordion expanded={expanded} style={{ borderRadius: 4, marginBottom: 24 }}>
        <AccordionSummary
          className={classes.summary}
          style={{
            borderBottom: expanded ? `1px solid ${grey[300]}` : 'none',
          }}
        >
          <Grid container className={classes.flex}>
            <Grid item>
              <Typography variant="h5" style={{ textTransform: 'none' }}>
                {`${unit.sort_index + 1}. ${unit.title}`}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                className={classes.icon}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="actions-menu"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem onClick={() => setOpenEditUnitDiag(true)}>
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  <ListItemText>{t('EDIT')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClickOpenOrderUnitDiag}>
                  <ListItemIcon>
                    <ReorderIcon />
                  </ListItemIcon>
                  <ListItemText>{t('APP.COURSES.CHAPTER.ORDER')}</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setOpenDestroyUnitDiag(true)}>
                  <ListItemIcon>
                    <DeleteForeverIcon />
                  </ListItemIcon>
                  <ListItemText>{t('DELETE')}</ListItemText>
                </MenuItem>
              </Menu>
              <IconButton className={classes.icon} onClick={() => setExpanded(!expanded)}>
                <ExpandMoreIcon />
              </IconButton>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid style={{ flexGrow: 1, paddingTop: 16, paddingLeft: 16, paddingRight: 16 }}>
            {unit?.chapters.map(chapter => (
              <CardChapterListManage
                key={chapter.id}
                chapter={chapter}
                urlLink={`${urlLink}/chapitres/${chapter.id}/edit`}
                onDeleteChapter={functions.onDeleteChapter}
              />
            ))}
            <Grid style={{ paddingTop: 24, textAlign: 'center', flexGrow: 1, paddingBottom: 16 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setShowChapterCreationDialog(true)}
                startIcon={<AddIcon />}
              >
                {t('APP.COURSES.CHAPTER.CREATE')}
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ConfirmationDialog
        openDialog={openDestroyUnitDiag}
        onCancel={handleClickCancelDestroyUnitDiag}
        onClickConfirm={handleClickConfirmDestroyUnitDiag}
        dialogTitle={t('APP.COURSES.UNIT.DELETE.CONFIRMATION_MESSAGE')}
      />
      <Dialog open={openEditUnitDiag} onClose={handleClickCancelEditUnitDiag} fullWidth>
        <Paper elevation={0}>
          <UnitForm
            unit={unit}
            onSubmit={handleClickConfirmEditUnitDiag}
            onClose={handleClickCancelEditUnitDiag}
          />
        </Paper>
      </Dialog>
      <Dialog
        open={showChapterCreationDialog}
        onClose={() => setShowChapterCreationDialog(false)}
        fullWidth
      >
        <Paper elevation={0}>
          <ChapterForm
            onSubmit={functions.onCreateChapter}
            onClose={() => setShowChapterCreationDialog(false)}
            unitId={unit.id}
          />
        </Paper>
      </Dialog>
      <Dialog open={openOrderUnitDiag} onClose={() => setOpenOrderUnitDiag(false)}>
        <ChapterOrderForm
          unit={unit}
          onClose={() => setOpenOrderUnitDiag(false)}
          onEditChaptersOrder={functions.onEditChaptersOrder}
        />
      </Dialog>
    </>
  );
};
export default CardUnitManage;
