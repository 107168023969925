import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoursesActions from '../../../Redux/CoursesRedux';
import NoMatch404 from '../../NoMatch404';
import CourseManage from '../../../Components/Shared/Courses/CourseManage';

const HoldingCourseManage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: orgaSlug,
    course_slug: courseSlug,
  } = match.params;

  const course = useSelector(state => state.courses.course);
  const isFetching = useSelector(state => state.courses.isFetching.getHoldingCourse);
  const errors = useSelector(state => state.courses.errors.getHoldingCourse);

  React.useEffect(() => {
    if (holdingSlug && courseSlug) {
      dispatch(CoursesActions.getHoldingCourseRequest(holdingSlug, courseSlug));
    }
  }, [holdingSlug, courseSlug, dispatch]);

  const onEditCourse = (event, course, cover, attachments) => {
    event.preventDefault();
    dispatch(CoursesActions.updateHoldingCourseRequest(holdingSlug, course, cover, attachments));
  };

  const onCreateUnit = (event, unit) => {
    event.preventDefault();
    dispatch(CoursesActions.createHoldingCourseUnitRequest(holdingSlug, course.slug, unit));
  };

  const onEditUnit = (event, unit) => {
    event.preventDefault();
    dispatch(CoursesActions.updateHoldingCourseUnitRequest(holdingSlug, course.slug, unit));
  };

  const onDeleteUnit = unit => {
    dispatch(CoursesActions.destroyHoldingCourseUnitRequest(holdingSlug, course.slug, unit.id));
  };

  const onCreateChapter = (event, chapter) => {
    event.preventDefault();
    dispatch(
      CoursesActions.createHoldingCourseChapterRequest(holdingSlug, orgaSlug, course.slug, chapter),
    );
  };

  const onDeleteChapter = chapterId => {
    dispatch(
      CoursesActions.destroyHoldingCourseChapterRequest(holdingSlug, course.slug, chapterId),
    );
  };

  const onEditChaptersOrder = unitAttributes => {
    dispatch(
      CoursesActions.updateHoldingCourseUnitRequest(holdingSlug, courseSlug, unitAttributes),
    );
  };

  return (
    <>
      {isFetching && <LinearProgress />}
      {!isFetching && course && `${courseSlug}` === `${course.slug}` && (
        <CourseManage
          course={course}
          functions={{
            onEditCourse,
            onCreateUnit,
            onEditUnit,
            onDeleteUnit,
            onCreateChapter,
            onDeleteChapter,
            onEditChaptersOrder,
          }}
        />
      )}
      {!isFetching && errors?.status === 404 && <NoMatch404 />}
    </>
  );
};

export default HoldingCourseManage;
