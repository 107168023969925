import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import _get from 'lodash/get';
import 'moment/locale/fr';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ChipInput from 'material-ui-chip-input';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import Editor from '../../Posts/EditorWysiwyg';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import { handleAddChip, handleRemoveChip } from '../../../Services/FormHelpers';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginFilePoster, FilePondPluginGetFile);
registerPlugin(FilePondPluginFileValidateType);

type Props = {
  article: Object,
  isCreating: Boolean,
  onSubmit: Function,
  visibilities: Array,
};

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
  },
  container: {
    justifyContent: 'space-around',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  selectLabel: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#fff',
  },
  buttons: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
  facet: {
    paddingLeft: theme.spacing(2),
  },
  formLabel: {
    paddingLeft: '3px',
    paddingRight: '3px',
    zIndex: '1002',
    backgroundColor: 'white',
  },
  divider: {
    margin: theme.spacing(1, 3),
  },
}));

function ArticleForm({ article, isCreating, onSubmit, visibilities }: Props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  // const expertisesFacets = useSelector(state => state.holding.expertisesFacets);
  const [title, setTitle] = React.useState(article?.title);
  const [content, setContent] = React.useState(article?.content || '');
  const [images, setImages] = React.useState([]);
  // eslint-disable-next-line camelcase
  // const [facetId, setFacetId] = React.useState(1);
  const [keyWords, setKeyWords] = React.useState(_get(article, 'related_words') || []);
  const [files, setFiles] = React.useState([]);
  const [visibility, setVisibility] = React.useState(article?.visibility || visibilities[0].value);
  const [goodToKnow, setGoodToKnow] = React.useState(article?.good_to_know || '');

  React.useEffect(() => {
    if (article) {
      const cover = article.cover_image_url;
      if (cover) {
        // current image preview
        setImages([
          {
            source: cover,
            options: {
              type: 'local',
              file: { name: cover, size: 0 },
              metadata: { poster: cover },
            },
          },
        ]);
      }

      if (article.attachments?.length > 0) {
        setFiles(
          article.attachments.map(attachment => ({
            source: attachment.download_url,
            options: {
              type: 'local',
              file: {
                id: attachment.id,
                name: attachment.filename,
                size: 0,
                url: attachment.download_url,
              },
              metadata: { poster: attachment },
            },
          })),
        );
      }
    }
  }, [article, setImages]);

  const handleCancel = () => {
    history.goBack();
  };

  const handleImageSelected = fileItems => {
    const im = fileItems.map(fileItem => fileItem.file);
    setImages(im);
  };
  return (
    <Grid container className={classes.layout}>
      <Paper className={classes.paper}>
        <form
          onSubmit={event =>
            onSubmit(
              event,
              {
                title,
                content: content || '',
                related_words: keyWords,
                visibility,
                good_to_know: goodToKnow || '',
              },
              images,
              files,
            )
          }
        >
          <Typography variant="h5" component="p" className={classes.title}>
            {t('APP.ARTICLES.CREATE')}
          </Typography>
          <Grid container className={classes.container}>
            <Grid item sm={4} xs={12}>
              <FormControl variant="outlined" component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.ARTICLES.TEXT_FIELD.LABEL.VISIBILITY')}
                </Typography>
                <Select
                  value={visibility}
                  onChange={event => setVisibility(event.target.value)}
                  input={<OutlinedInput labelWidth={0} name="role" id="select-roles" />}
                  fullWidth
                  variant="outlined"
                  disabled={visibilities.length === 1}
                >
                  {visibilities.map(v => (
                    <MenuItem key={v.value} value={v.value}>
                      {t(v.label)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.ARTICLES.TEXT_FIELD.LABEL.TITLE')}
                </Typography>
                <TextField
                  required
                  name="title"
                  value={title || ''}
                  type="text"
                  onChange={event => setTitle(event.target.value)}
                  variant="outlined"
                  autoFocus
                  fullWidth
                />
              </FormControl>
              {/* {expertisesFacets && (
                <FormControl component="fieldset" fullWidth>
                  <Typography className={classes.label}>
                    {t('APP.ARTICLES.TEXT_FIELD.LABEL.THEME')}
                  </Typography>
                  <Select
                    value={facetId}
                    onChange={event => setFacetId(event.target.value)}
                    fullWidth
                    variant="outlined"
                  >
                    {expertisesFacets.map(facet => (
                      <MenuItem key={facet.id} value={facet.id}>
                        <Grid container>
                          {getExpertiseFacetStyle(facet.name).mediumIcon}
                          <Typography className={classes.facet}>{facet.name}</Typography>
                        </Grid>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )} */}
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.ARTICLES.ARTICLES_KEYWORDS.TITLE')}
                </Typography>
                <ChipInput
                  fullWidth
                  autoFocus
                  multiline="true"
                  rows={4}
                  value={keyWords}
                  onDelete={chip => setKeyWords(handleRemoveChip(chip, keyWords))}
                  onAdd={chip => setKeyWords(handleAddChip(chip, keyWords))}
                  placeholder={t('APP.ARTICLES.ARTICLES_KEYWORDS.PLACEHOLDER')}
                  variant="outlined"
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.ARTICLES.TEXT_FIELD.LABEL.COVER')}
                </Typography>
                <FilePond
                  files={images}
                  allowMultiple={false}
                  maxFiles={1}
                  instantUpload={false}
                  acceptedFileTypes={['image/*']}
                  labelIdle={t('APP.USER.IMAGE_FIELD.PLACEHOLDER')}
                  onupdatefiles={fileItems => handleImageSelected(fileItems)}
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>{t('APP.ARTICLES.FILES.TITLE')}</Typography>
                <FilePond
                  files={files}
                  maxFiles={4}
                  allowMultiple
                  instantUpload={false}
                  credits={('', '')}
                  acceptedFileTypes={['application/*']}
                  labelIdle={t('APP.USER.ONE_ATTACHMENT_FIELD.PLACEHOLDER')}
                  onupdatefiles={fileItems => {
                    setFiles(fileItems.map(fileItem => fileItem.file));
                  }}
                />
              </FormControl>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>{t('APP.ARTICLES.GOOD_TO_KNOW')}</Typography>
                <Editor
                  name="good_to_know"
                  value={article?.good_to_know || ''}
                  onChange={event => setGoodToKnow(event.target.value)}
                  options={['inline', 'link']}
                  editorStyle={{ height: '100px' }}
                />
              </FormControl>
            </Grid>
            <Hidden only={['xs']}>
              <Divider sm={1} orientation="vertical" flexItem className={classes.divider} />
            </Hidden>
            <Grid item sm={7} xs={12}>
              <FormControl component="fieldset" fullWidth>
                <Typography className={classes.label}>
                  {t('APP.ARTICLES.TEXT_FIELD.LABEL.CONTENT')}
                </Typography>
                <Editor
                  name="content"
                  value={article?.content || ''}
                  onChange={event => setContent(event.target.value)}
                  options={['blockType', 'inline', 'list', 'link']}
                  editorStyle={{ height: '720px' }}
                />
              </FormControl>
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button disabled={isCreating} color="primary" onClick={handleCancel}>
              {t('CANCEL')}
            </Button>
            <Button disabled={isCreating} color="primary" type="submit">
              {isCreating ? <CircularProgress size={24} /> : t('SEND')}
            </Button>
          </div>
        </form>
      </Paper>
    </Grid>
  );
}

export default ArticleForm;
