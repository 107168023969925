import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import CourseLayoutContainer from '../../Layout/LayoutContainer';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AddIcon from '@mui/icons-material/Add';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import CardCourseManage from './CardCourseManage';
import CardUnitManage from './CardUnitManage';
import UnitForm from './UnitForm';

type Props = {
  course: Object,
  functions: Object,
};

const CourseManage = ({ course, functions }: Props) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const {
    params: { organisation_slug: orgaSlug },
  } = match;
  const [showUnitCreationDialog, setShowUnitCreationDialog] = React.useState(false);
  const urlRoot =
    course.postable_type === 'League'
      ? `/leagues/${course.postable_id}/formations`
      : `/${course.postable_slug}/${orgaSlug}/formations`;

  return (
    <Grid style={{ paddingTop: 16 }}>
      <CourseLayoutContainer
        menu={
          <CardCourseManage course={course} onEdit={functions.onEditCourse} urlLink={urlRoot} />
        }
      >
        <Grid>
          {course?.units.map(unit => (
            <CardUnitManage
              key={unit.id}
              unit={unit}
              functions={functions}
              urlLink={`${urlRoot}/${course.slug}`}
            />
          ))}
          <Grid style={{ paddingBottom: 16, textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowUnitCreationDialog(true)}
              startIcon={<AddIcon />}
            >
              {t('APP.COURSES.UNIT.CREATE')}
            </Button>
          </Grid>
        </Grid>
      </CourseLayoutContainer>
      <Dialog
        open={showUnitCreationDialog}
        onClose={() => setShowUnitCreationDialog(false)}
        fullWidth
      >
        <Paper elevation={0}>
          <UnitForm
            onSubmit={functions.onCreateUnit}
            onClose={() => setShowUnitCreationDialog(false)}
          />
        </Paper>
      </Dialog>
    </Grid>
  );
};

export default CourseManage;
