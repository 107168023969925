import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import { useTranslation } from 'react-i18next';

import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddCircle from '@material-ui/icons/AddCircle';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import LeagueLayoutContainer from '../../../Components/Layout/LayoutContainer';
import AuthMenu from '../../../Components/Leagues/Menu';
import CoursesActions from '../../../Redux/CoursesRedux';
import EmptyList from '../../../Components/Shared/EmptyList';
import { isLeagueAdmin } from '../../../Acl/Controls';
import CardCourse from '../../../Components/Shared/Courses/CardCourse';
import CourseForm from '../../../Components/Shared/Courses/CourseForm';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
  },
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(3),
    '&>*': {
      boxShadow: '0px 1px 3px 0.5px rgb(0 0 0 / 5%)',
      borderRadius: '4px',
    },
  },
  grow: {
    flexGrow: 1,
    padding: theme.spacing(3, 0),
  },
  progress: {
    marginTop: theme.spacing(-1),
  },
  paper: {
    alignSelf: 'center',
  },
  cardCourse: {
    marginBottom: theme.spacing(1),
  },
}));

const LeagueCourses = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const match = useRouteMatch();
  const { league_id: leagueId } = match.params;
  const dispatch = useDispatch();

  const league = useSelector(state => state.leagues.league);
  const courses = useSelector(state => state.courses.courses);
  const currentUser = useSelector(state => state.auth.currentUser);
  const isFetching = useSelector(state => state.courses.isFetching.getLeagueCourses);
  const visibilities = [{ label: 'APP.COURSES.VISIBILITY.PUBLIC', value: 'public' }];
  const [openCourseFormDialog, setOpenCourseFormDialog] = React.useState(false);

  React.useEffect(() => {
    if (leagueId) {
      dispatch(CoursesActions.getLeagueCoursesRequest(leagueId));
    }
  }, [leagueId, dispatch]);

  const onDeleteCourse = course => {
    dispatch(CoursesActions.destroyLeagueCourseRequest(leagueId, course.slug));
  };

  const onSubmit = (event, course, cover, attachments) => {
    event.preventDefault();

    const courseAttributes = { ...course, postable_type: 'League' };
    dispatch(
      CoursesActions.createLeagueCourseRequest(leagueId, courseAttributes, cover, attachments),
    );
  };

  return (
    <>
      <LeagueLayoutContainer menuWidth={1} menu={<AuthMenu league={league} activeItem="courses" />}>
        <div className={classes.appBarContainer}>
          <AppBar position="static" color="inherit">
            <Toolbar>
              <Typography className={classes.grow} variant="h5">
                {t('APP.COURSES.APP_BAR.TITLE')}
              </Typography>
              <Tooltip title={t('APP.COURSES.CREATE')} placement="top">
                <IconButton onClick={() => setOpenCourseFormDialog(true)}>
                  <AddCircle />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
        </div>

        {isFetching && (
          <Grid item xs={12}>
            <LinearProgress className={classes.progress} />
          </Grid>
        )}
        {!isFetching &&
          courses &&
          courses.length !== 0 &&
          currentUser &&
          `${leagueId}` === `${league.id}` && (
            <>
              {courses.map(course => (
                <Grid item xs={12} key={course.slug} className={classes.cardCourse}>
                  <CardCourse
                    canEdit={isLeagueAdmin(league.slug, currentUser)}
                    course={course}
                    linkUrl={`/formations/${course.slug}`}
                    editUrl={`/leagues/${leagueId}/formations/${course.slug}`}
                    onDelete={onDeleteCourse}
                  />
                </Grid>
              ))}
            </>
          )}
        {!isFetching && courses && courses.length === 0 && (
          <EmptyList id="APP.COURSES.EMPTY" level="info" />
        )}
      </LeagueLayoutContainer>
      <Dialog
        open={openCourseFormDialog}
        onClose={() => setOpenCourseFormDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <Paper className={classes.paper} elevation={0}>
          <CourseForm
            onSubmit={onSubmit}
            onClose={() => setOpenCourseFormDialog(false)}
            visibilities={visibilities}
          />
        </Paper>
      </Dialog>
    </>
  );
};

export default LeagueCourses;
