import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteMatch, useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import VisibilityIcon from '@mui/icons-material/Visibility';

import HoldingActions from '../../../Redux/HoldingRedux';
import { isServiceOther } from '../../../Acl/Controls';
import ServiceForm from '../../../Pages/Holding/Settings/ServiceForm';
import { Button, CardContent, DialogActions, DialogTitle } from '@material-ui/core';
import Routes from '../../../Routing/Routes';
import ServiceImage from '../Shared/ServiceImage';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(0.5),
    height: 312,
  },
  cardContent: { height: 312 },
  cardHeader: { paddingBottom: 0 },
  title: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '2',
  },
  description: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '4',
    '&>*': {
      margin: 0,
    },
  },
  dialogWidth: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 800,
    },
  },
}));

type Props = {
  service: Object,
  organisation: Object,
  canEditHolding: Boolean,
};

const CardService = ({ service, organisation, canEditHolding }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch();
  const { holding_slug: holdingSlug, organisation_slug: orgaSlug } = match.params;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showServiceForm, setShowServiceForm] = React.useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = React.useState(false);

  const isOther = isServiceOther(service);

  const handleRemove = () => {
    dispatch(HoldingActions.removeHoldingServiceRequest(organisation.holding_slug, service.id));
    setAnchorEl(null);
    setShowConfirmRemove(false);
  };

  const handleCloseServiceForm = () => {
    setShowServiceForm(false);
    setAnchorEl(null);
  };

  const handleClick = () => {
    if (service && service.id) {
      history.push(Routes.customQuotationPath(organisation, service));
    }
  };

  const renderContent = () => {
    const open = Boolean(anchorEl);
    return (
      <Grid className={classes.cardContent}>
        <ServiceImage service={service} isImageWithIcon />
        <CardHeader
          title={
            <Typography variant="h6" component="h4" className={classes.title}>
              {service?.label}
            </Typography>
          }
          action={
            canEditHolding && (
              <>
                <IconButton
                  onClick={event => setAnchorEl(event.currentTarget)}
                  aria-label="More"
                  aria-owns={open ? 'actions-menu' : undefined}
                  aria-haspopup="true"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="actions-menu"
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                >
                  {!isOther && (
                    <MenuItem onClick={handleClick}>
                      <ListItemIcon>
                        <ShoppingBasketIcon />
                      </ListItemIcon>
                      <ListItemText>{t('APP.ORGANISATION.HOME.SERVICES.ACTION')}</ListItemText>
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() =>
                      history.push(`/${holdingSlug}/${orgaSlug}/services/${service?.slug}`)
                    }
                  >
                    <ListItemIcon>
                      <VisibilityIcon />
                    </ListItemIcon>
                    <ListItemText>{t('CONSULT')}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setShowServiceForm(true)}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>{t('APP.ORGANISATION.SETTINGS.SERVICE.MENU.EDIT')}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setShowConfirmRemove(true)}>
                    <ListItemIcon>
                      <DeleteIcon />
                    </ListItemIcon>
                    <ListItemText>
                      {t('APP.ORGANISATION.SETTINGS.SERVICE.MENU.REMOVE')}
                    </ListItemText>
                  </MenuItem>
                </Menu>
              </>
            )
          }
          className={classes.cardHeader}
        />
        <CardContent>
          <Typography
            variant="body2"
            className={classes.description}
            dangerouslySetInnerHTML={{ __html: service?.description }}
          />
        </CardContent>
      </Grid>
    );
  };

  return (
    <>
      <Card itemScope className={classes.card}>
        {canEditHolding ? (
          renderContent()
        ) : (
          <CardActionArea
            onClick={() => history.push(`/${holdingSlug}/${orgaSlug}/services/${service?.slug}`)}
          >
            {renderContent()}
          </CardActionArea>
        )}
      </Card>
      <Dialog
        open={showServiceForm}
        onClose={handleCloseServiceForm}
        className={classes.dialogWidth}
      >
        <ServiceForm
          organisation={organisation}
          service={service}
          onClose={handleCloseServiceForm}
        />
      </Dialog>
      <Dialog open={showConfirmRemove} onClose={() => setShowConfirmRemove(false)}>
        <DialogTitle>{t('APP.ORGANISATION.HOME.SERVICES.CONFIRM_REMOVE')}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button onClick={() => setShowConfirmRemove(false)} color="primary">
            {t('CANCEL')}
          </Button>
          <Button onClick={handleRemove}>{t('OK')}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardService;
