/* eslint-disable global-require */
import React, { memo, useState } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import { EditorState } from 'draft-js';

import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { makeStyles } from '@material-ui/core';
import { isServerSide } from '../../Config/ServerConfig';
import { grey } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: `solid 1px ${grey[400]}`,
    borderRadius: 4,
  },
  editor: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .DraftEditor-root': {
      height: 'auto',
    },
  },
  toolbar: {
    paddingTop: theme.spacing(1),
    border: 'none',
    borderBottom: `solid 1px ${grey[400]}`,
    borderRadius: '4px 4px 0 0',
    '& .rdw-option-wrapper': {
      border: 'none',
      '&:hover': {
        backgroundColor: grey[200],
        boxShadow: 'none',
      },
    },
    '& .rdw-option-active': {
      backgroundColor: grey[200],
      boxShadow: 'none',
    },
  },
}));

type Props = {
  label: String,
  name: String,
  value: String,
  required: Boolean,
  onChange: Function,
  options: Array,
  toolbarHidden: Boolean,
  readOnly: Boolean,
  editorStyle: Object,
};

// https://jpuri.github.io/react-draft-wysiwyg/#/docs
function EditorWysiwyg({
  label,
  name,
  required,
  value,
  onChange,
  options,
  toolbarHidden,
  readOnly,
  editorStyle,
}: Props) {
  const classes = useStyles();
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromHTML(value || '')),
  );

  if (isServerSide()) return null;
  const { Editor } = require('react-draft-wysiwyg');

  React.useEffect(() => {
    setEditorState(EditorState.createWithContent(stateFromHTML(value)));
  }, [value]);

  const handleChange = newState => {
    setEditorState(newState);
    const content = stateToHTML(newState.getCurrentContent());
    if (onChange) {
      onChange({ target: { value: content, type: 'wysiwig' } });
    }
  };

  return (
    <>
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <Editor
        toolbar={{
          options,
          inline: { options: ['bold', 'italic', 'underline', 'strikethrough'] },
          blockType: {
            options: ['Normal', 'H2', 'H3', 'H4', 'H5', 'H6'],
          },
        }}
        editorState={editorState}
        wrapperClassName={classes.wrapper}
        editorClassName={classes.editor}
        toolbarClassName={classes.toolbar}
        onEditorStateChange={handleChange}
        name={name}
        required={required}
        toolbarHidden={toolbarHidden}
        readOnly={readOnly}
        editorStyle={editorStyle}
      />
    </>
  );
}

export default memo(EditorWysiwyg);
