import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch, Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { Avatar, Card, CardContent, CardHeader, LinearProgress } from '@material-ui/core';

import LayoutStyles from '../../../Components/Layout/Styles/LayoutStyles';
import OrganisationActions from '../../../Redux/OrganisationRedux';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import { isServiceOther, isServiceTransport } from '../../../Acl/Controls';
import Routes from '../../../Routing/Routes';
import Seo from '../../../Routing/Seo';
import { getSettings, formatHours, getSeoDescription } from '../../../Services/DataHelper';
import WelloAvatar from '../../../Components/Shared/WelloAvatar';
import Slider from 'react-slick';
import CardService from '../../../Components/Organisations/Home/CardService';
import ServiceDetail from '../../../Components/Organisations/Shared/ServiceDetail';
import CardHoldingHome from '../../../Components/Holdings/CardHoldingHome';
import ServiceImage from '../../../Components/Organisations/Shared/ServiceImage';
import NoMatch404 from '../../NoMatch404';
import { getCdnUrl } from '../../../Services/ImageResizer';
import { getOrgaName } from '../../../Services/OrganisationHelper';

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '40px',
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
    },
  },
  buttonLink: {
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  serviceIcon: {
    backgroundColor: 'White',
    marginRight: theme.spacing(2),
    width: '45px',
    height: '45px',
    '&>*': {
      width: '45px',
      height: '45px',
    },
  },
  divider: {
    marginBottom: theme.spacing(3),
  },
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  h5: {
    textTransform: 'none',
  },
  content: {
    padding: theme.spacing(4),
  },
  orgaHeader: {
    padding: theme.spacing(0, 2),
  },
  textMargin: {
    marginBottom: theme.spacing(2),
  },
}));

const ShowService = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: orgaSlug,
    service_slug: serviceSlug,
  } = match.params;
  const organisationService = useSelector(state => state.organisation.organisationService);
  const isFetching = useSelector(state => state.organisation.isFetching.getOrganisationService);
  const errors = useSelector(state => state.organisation.errors);
  const organisation = organisationService?.organisation;
  const service = organisationService?.service;
  const sampledServices = organisationService?.sampled_services;
  const isTransport = isServiceTransport(service);
  const isOther = isServiceOther(service);
  const settings = getSettings([4, 3, 2, 1]);

  React.useEffect(() => {
    dispatch(OrganisationActions.getOrganisationServiceRequest(holdingSlug, orgaSlug, serviceSlug));
  }, [dispatch, holdingSlug, orgaSlug, serviceSlug]);

  return (
    <>
      {isFetching && <LinearProgress className={classes.progress} />}
      {!isFetching && errors?.status === 404 && <NoMatch404 />}
      {!isFetching && organisationService && service?.slug === serviceSlug && (
        <>
          <Helmet>
            {Seo.title(`${service?.label}${t(`${Routes.currentNamespace()}.SEO.SUFFIX`)}`)}
            {Seo.description(getSeoDescription(service?.description))}
            {service?.cover_key_basic_dpi &&
              Seo.image(getCdnUrl(service?.cover_key_basic_dpi, 800))}
            {Seo.canonical(organisation?.holding?.league_id, match.url)}
          </Helmet>
          <div itemScope itemProp="service" itemType="https://schema.org/Service">
            <meta itemProp="name" content={service?.label} />
            <meta itemProp="description" content={service?.description} />
            {service?.cover_key_basic_dpi && (
              <meta itemProp="image" content={service?.cover_key_basic_dpi} />
            )}
            <meta itemProp="url" content={`/${holdingSlug}/${orgaSlug}/services/${serviceSlug}`} />
            <div itemScope itemProp="provider" itemType="https://schema.org/Organization">
              <meta itemProp="name" content={getOrgaName(organisation)} />
            </div>
          </div>

          <Grid className={classes.layout}>
            <Button
              component={Link}
              to={`/${holdingSlug}/${orgaSlug}`}
              className={classes.buttonLink}
            >
              <ArrowLeftIcon /> {t('APP.SERVICE.SHOW.BACK')}
            </Button>
            {/* LEFT SECTION */}
            <Grid container>
              <Grid item md={8} xs={12} className={classes.content}>
                {/* TITLE */}
                <Grid container alignItems="center" className={classes.marginBottom} wrap="nowrap">
                  <Avatar
                    className={classes.serviceIcon}
                    style={{
                      color: getExpertiseStyle(service.expertise).color,
                    }}
                  >
                    {getExpertiseStyle(service.expertise).icon}
                  </Avatar>
                  <Typography variant="h3" component="h1">
                    {service.label}
                  </Typography>
                </Grid>
                {/* SERVICE DESCRIPTION */}
                <Typography variant="h5" component="div" gutterBottom className={classes.h5}>
                  {t('APP.SERVICE.SHOW.DESCRIPTION')}
                </Typography>
                <Divider className={classes.divider} />
                <Typography
                  className={classes.marginBottom}
                  dangerouslySetInnerHTML={{ __html: t(service.description) }}
                />
                {/* SERVICE DETAILS */}
                {(service.escort_rate || service.price_description || isTransport) && (
                  <>
                    <Typography variant="h5" component="div" gutterBottom className={classes.h5}>
                      {t('APP.SERVICE.SHOW.DETAIL')}
                    </Typography>
                    <Divider className={classes.divider} />
                    <ServiceDetail
                      service={service}
                      isTransport={isTransport}
                      openedAt={formatHours(service?.opened_at)}
                    />
                  </>
                )}
              </Grid>

              {/* RIGHT SECTION */}
              <Grid item md={4} xs={12} className={classes.content}>
                <Card>
                  <ServiceImage service={service} />
                  <CardContent>
                    <Typography variant="overline" component="div">
                      {t('APP.SERVICE.SHOW.ORGA_DETAIL')}
                    </Typography>
                  </CardContent>
                  <CardHeader
                    avatar={
                      <WelloAvatar
                        avatarUrl={organisation?.holding?.cover_url}
                        avatarStyle={{ height: 32, width: 32 }}
                      />
                    }
                    title={organisation?.holding?.name}
                    subheader={organisation?.name}
                    className={classes.orgaHeader}
                  />
                  <CardContent>
                    <CardHoldingHome organisation={organisation} />
                  </CardContent>
                  <Divider />
                  <CardContent>
                    {service?.performed_by && service?.performed_by !== 'null' && (
                      <Typography variant="body2" className={classes.textMargin}>
                        {t('APP.ORGANISATION.SETTINGS.SERVICE.PERFORMED')}
                        {t(`APP.ORGANISATION.SETTINGS.SERVICE.PERFORMED.${service?.performed_by}`)}
                      </Typography>
                    )}
                    <Grid>
                      <Grid>
                        {organisation?.admin_id && (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              history.push(Routes.customQuotationPath(organisation, service))
                            }
                            fullWidth
                          >
                            {isOther
                              ? t('APP.ORGANISATION.HOME.SERVICES.CONTACT')
                              : t('APP.ORGANISATION.HOME.SERVICES.ACTION')}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            {/* OTHER SERVICES */}
            {sampledServices?.length > 0 && (
              <Grid className={classes.content}>
                <Typography variant="h4" component="div" gutterBottom>
                  {t('APP.SERVICE.SHOW.SAMPLED_SERVICES')}
                </Typography>
                <Divider className={classes.divider} />

                <Slider {...settings} className={classes.slider}>
                  {sampledServices
                    .map(os => ({ ...os.service }))
                    ?.map(service => (
                      <Grid key={service.slug}>
                        <CardService service={service} organisation={organisation} />
                      </Grid>
                    ))}
                </Slider>
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default ShowService;
