import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import LaptopIcon from '@material-ui/icons/Laptop';
import AttachmentIcon from '@material-ui/icons/Attachment';
import Link from '@material-ui/core/Link';

import ButtonCallMeBackInModal from '../Shared/ButtonCallMeBackInModal';
import { formatUrl, getFormattedPhone } from '../../Services/DataHelper';

type Props = {
  organisation: Object,
  showCta: Boolean,
};

const useStyles = makeStyles(theme => ({
  infoContainer: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    color: '#464646de',
  },
  info: {
    fontSize: '0.8rem',
    paddingLeft: theme.spacing(1),
  },
}));

const CardHoldingHome = ({ organisation, showCta }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const hasHoldingAdmin = Boolean(organisation?.holding?.admin_id);

  return (
    <Grid>
      {organisation?.holding?.phone && (
        <Grid className={classes.infoContainer}>
          <PhoneIcon fontSize="small" />
          <Typography variant="body2" align="left" className={classes.info}>
            {getFormattedPhone(organisation?.holding.phone)}
          </Typography>
          {hasHoldingAdmin && showCta && (
            <div className={classes.info}>
              <ButtonCallMeBackInModal
                organisation={organisation}
                withPhone
                withEmail
                gtmOrigin="home_card"
              />
            </div>
          )}
        </Grid>
      )}
      {organisation?.holding?.contact_email && (
        <Grid className={classes.infoContainer}>
          <EmailIcon fontSize="small" />
          <Typography variant="body2" align="left" className={classes.info}>
            {organisation?.holding?.contact_email}
          </Typography>
        </Grid>
      )}
      {organisation?.holding?.formatted_address && (
        <Grid className={classes.infoContainer}>
          <RoomIcon fontSize="small" />
          <Typography variant="body2" align="left" className={classes.info}>
            {organisation?.holding.formatted_address}
          </Typography>
        </Grid>
      )}
      {organisation?.holding?.external_link && (
        <Grid className={classes.infoContainer}>
          <LaptopIcon fontSize="small" />
          <Link
            href={formatUrl(organisation?.holding?.external_link)}
            color="inherit"
            target="_blank"
            className={classes.info}
            component="a"
          >
            {t('APP.ORGANISATION.HOME.EXTERNAL_LINK')}
          </Link>
        </Grid>
      )}
      {organisation?.holding?.charters?.length > 0 && (
        <Grid className={classes.infoContainer}>
          <AttachmentIcon fontSize="small" />
          <Link
            href={organisation?.holding?.charters[0]?.download_url}
            color="inherit"
            target="_blank"
            className={classes.info}
          >
            {t('APP.HOLDING.SETUP.TEXT_FIELD.LABEL.CHARTER')}
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default CardHoldingHome;
