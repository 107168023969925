import React from 'react';
import { useTranslation } from 'react-i18next';

import 'moment/locale/fr';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import LayoutStyles from '../../Layout/Styles/LayoutStyles';

type Props = {
  unit: Object,
  onSubmit: Function,
  onClose: Function,
};

const useStyles = makeStyles(theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
  },
  paper: {
    padding: theme.spacing(3),
  },
  container: {
    justifyContent: 'space-around',
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    paddingTop: theme.spacing(2),
    textAlign: 'center',
  },
  label: {
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
    textAlign: 'center',
  },
}));

function UnitForm({ unit, onSubmit, onClose }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [title, setTitle] = React.useState(unit?.title);

  return (
    <Paper className={classes.paper}>
      <form
        onSubmit={event => {
          onSubmit(event, {
            id: unit?.id,
            title,
          });
          onClose();
        }}
      >
        <Typography variant="h5" component="p" className={classes.title}>
          {unit?.id ? t('APP.COURSES.UNIT.EDIT') : t('APP.COURSES.UNIT.CREATE')}
        </Typography>
        <Grid container className={classes.container}>
          <FormControl component="fieldset" fullWidth>
            <Typography className={classes.label}>
              {t('APP.COURSES.TEXT_FIELD.LABEL.TITLE')}
            </Typography>
            <TextField
              required
              name="title"
              value={title || ''}
              type="text"
              onChange={event => setTitle(event.target.value)}
              variant="outlined"
              autoFocus
              fullWidth
            />
          </FormControl>
        </Grid>
        <div className={classes.buttons}>
          <Button color="primary" onClick={onClose}>
            {t('CANCEL')}
          </Button>
          <Button color="primary" type="submit">
            {t('SEND')}
          </Button>
        </div>
      </form>
    </Paper>
  );
}

export default UnitForm;
