import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import ChapterLayoutContainer from '../../Layout/LayoutContainer';

import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@material-ui/core/Button';
import CardChapterManage from './CardChapterManage';
import Editor from '../../Posts/EditorWysiwyg';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import Routes from '../../../Routing/Routes';
import { getLeagueQuizzes } from '../../../Services/CourseHelper';

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageExifOrientation);
registerPlugin(FilePondPluginFilePoster);
registerPlugin(FilePondPluginFilePoster, FilePondPluginGetFile);
registerPlugin(FilePondPluginFileValidateType);

const useStyles = makeStyles(theme => ({
  formControl: {
    backgroundColor: 'white',
    padding: theme.spacing(3),
    borderRadius: 4,
    marginBottom: theme.spacing(5),
  },
  button: {
    paddingTop: 16,
    textAlign: 'center',
  },
  label: {
    paddingBottom: theme.spacing(3),
  },
}));

type Props = {
  chapter: Object,
  onEdit: Function,
  urlLink: String,
};

const ChapterManage = ({ chapter, onEdit, urlLink }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [content, setContent] = React.useState(chapter?.content || '');
  const [url, setUrl] = React.useState(chapter?.asset_type === 'iframe' ? chapter?.url_link : '');
  const [files, setFiles] = React.useState([]);
  const classes = useStyles();
  const isText = chapter?.asset_type === 'text';
  const isIframe = chapter?.asset_type === 'iframe';
  const isVideo = chapter?.asset_type === 'video';
  const isImage = chapter?.asset_type === 'image';
  const isAudio = chapter?.asset_type === 'audio';
  const isQuiz = chapter?.asset_type === 'quiz';

  const sendAttachments = !isIframe && !isQuiz;

  const getAllowedFileTypes = () => {
    if (isText) return ['application/*'];
    if (isVideo) return ['video/*'];
    if (isImage) return ['image/png', 'image/jpeg', 'image/webp'];
    if (isAudio) return ['audio/*'];

    return null;
  };

  const onEditChapter = e => {
    const chapterAttributes = {
      id: chapter.id,
      content,
      url_link: isIframe ? url : null,
    };
    onEdit(e, chapterAttributes, sendAttachments ? files : null);
  };

  const onEditChapterTite = (e, chapterAttributes) => {
    onEdit(e, chapterAttributes, sendAttachments ? files : null);
  };

  React.useEffect(() => {
    if (isText && chapter?.attachments?.length > 0) {
      setFiles(
        chapter.attachments.map(attachment => ({
          source: attachment.download_url,
          options: {
            type: 'local',
            file: {
              name: attachment.filename,
              size: 0,
              url: attachment.download_url,
              id: attachment.id,
            },
            metadata: { poster: attachment },
          },
        })),
      );
    } else if (!isIframe && chapter?.url_link)
      setFiles([
        {
          source: chapter?.url_link,
          options: {
            type: 'local',
            file: { name: chapter?.url_link, size: 0 },
            metadata: { poster: chapter?.url_link },
          },
        },
      ]);
  }, [chapter]);

  return (
    <Grid style={{ paddingTop: 16 }}>
      <ChapterLayoutContainer
        menu={
          <>
            <CardChapterManage chapter={chapter} onEdit={onEditChapterTite} />
            <Grid className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                onClick={onEditChapter}
                startIcon={<SaveIcon />}
              >
                {t('SAVE')}
              </Button>
            </Grid>
            <Grid className={classes.button}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => history.push(`${urlLink}/edit`)}
                startIcon={<ArrowBackIcon />}
              >
                {t('APP.COURSES.CHAPTER.GO_BACK')}
              </Button>
            </Grid>
          </>
        }
      >
        <Grid>
          {sendAttachments && (
            <FormControl component="fieldset" fullWidth className={classes.formControl}>
              <Typography className={classes.label}>
                {t(`APP.COURSES.FILES.${chapter?.asset_type?.toUpperCase()}.TITLE`)}
              </Typography>
              <FilePond
                files={files}
                maxFiles={isText ? 4 : 1}
                allowMultiple={isText}
                instantUpload={false}
                credits={('', '')}
                acceptedFileTypes={getAllowedFileTypes()}
                labelIdle={t(`APP.COURSES.FILES.${chapter?.asset_type?.toUpperCase()}.PLACEHOLDER`)}
                onupdatefiles={fileItems => {
                  setFiles(fileItems.map(fileItem => fileItem.file));
                }}
              />
            </FormControl>
          )}
          {isIframe && (
            <FormControl component="fieldset" fullWidth className={classes.formControl}>
              <Typography className={classes.label}>{t('APP.COURSES.URL.TITLE')}</Typography>
              <TextField
                required
                name="title"
                value={url || ''}
                type="text"
                onChange={event => setUrl(event.target.value)}
                variant="outlined"
                autoFocus
                fullWidth
                placeholder={t('APP.COURSES.URL.PLACE_HOLDER')}
              />
            </FormControl>
          )}
          {!isQuiz && (
            <FormControl component="fieldset" fullWidth className={classes.formControl}>
              <Editor
                name="content"
                value={chapter?.content || ''}
                onChange={event => setContent(event.target.value)}
                options={['blockType', 'inline', 'list', 'link']}
                editorStyle={{ height: '400px' }}
              />
            </FormControl>
          )}
          {isQuiz && (
            <FormControl component="fieldset" fullWidth className={classes.formControl}>
              <Typography className={classes.label}>{t('APP.COURSES.QUIZ.TITLE')}</Typography>
              <Select
                value={content}
                onChange={event => setContent(event.target.value)}
                input={<OutlinedInput labelWidth={0} name="role" id="select-visibility" />}
                fullWidth
                variant="outlined"
              >
                {getLeagueQuizzes(Routes.currentLeague().namespace).map(v => (
                  <MenuItem key={v.value} value={v.value}>
                    {t(v.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid>
      </ChapterLayoutContainer>
    </Grid>
  );
};

export default ChapterManage;
