import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import { Metrics } from '../../Themes';
import LayoutStyles from './Styles/LayoutStyles';

type Props = {
  classes: Object,
  children: Object,
  menu: React.Component,
  rightMenu: React.Component,
  menuWidth: Number,
  backBtn: React.Component,
  hidePadding: Boolean,
};

const styles = theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
  },
  navbarPaddingTop: {
    paddingTop: -Metrics.navBarHeight,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      paddingTop: -Metrics.navBarHeight + theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: -Metrics.navBarHeight - theme.spacing(1),
    },
  },
  padding: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
  },
});

function LayoutContainer(props: Props) {
  const { classes, children, menu, rightMenu, menuWidth, backBtn, hidePadding } = props;

  return (
    <div className={classes.layout}>
      {backBtn}
      <Grid container spacing={4}>
        {menu && (
          <Grid item md={menuWidth || 3} sm={12} xs={12} className={hidePadding && classes.padding}>
            {menu}
          </Grid>
        )}

        {!rightMenu && menu && (
          <Grid
            item
            md={menuWidth ? 12 - menuWidth : 9}
            sm={12}
            xs={12}
            className={hidePadding && classes.padding}
          >
            {children}
          </Grid>
        )}

        {!rightMenu && !menu && (
          <Grid item md={12} sm={12} xs={12} className={hidePadding && classes.padding}>
            {children}
          </Grid>
        )}

        {rightMenu && (
          <>
            <Grid item md={6} sm={12} xs={12} className={hidePadding && classes.padding}>
              {children}
            </Grid>

            <Grid item md={3} sm={12} xs={12} className={hidePadding && classes.padding}>
              {rightMenu}
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
}

export default withStyles(styles)(LayoutContainer);
