import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArticleForm from '../../../Components/Shared/Articles/ArticleForm';
import 'moment/locale/fr';
import ArticlesActions from '../../../Redux/ArticlesRedux';
import { LEAGUE_VISIBILITIES } from '../../../Services/ArticleHelper';

type Props = {
  article: Object,
};

function LeagueArticleForm({ article }: Props) {
  const league = useSelector(state => state.leagues.league);
  const isCreating = useSelector(
    state =>
      state.articles.isFetching.createLeagueArticle ||
      state.articles.isFetching.updateLeagueArticle,
  );
  const dispatch = useDispatch();

  const handleSubmit = (event, articleData, images, attachments) => {
    event.preventDefault();

    if (article) {
      dispatch(
        ArticlesActions.updateLeagueArticleRequest(
          league.id,
          { ...articleData, slug: article.slug },
          images[0],
          attachments,
        ),
      );
    } else {
      dispatch(
        ArticlesActions.createLeagueArticleRequest(league.id, articleData, images[0], attachments),
      );
    }
  };

  return (
    <>
      {league && (
        <ArticleForm
          article={article}
          onSubmit={handleSubmit}
          isCreating={isCreating}
          visibilities={LEAGUE_VISIBILITIES}
        />
      )}
    </>
  );
}

export default LeagueArticleForm;
