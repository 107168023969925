import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';

type Props = {
  unit: Object,
  onClose: Function,
  onEditChaptersOrder: Function,
};

const useStyles = makeStyles(theme => ({
  paper: {
    ...LayoutStyles.centered800Layout,
    padding: theme.spacing(4),
  },
  headingContainer: {
    width: '100%',
  },
  formLabel: {
    paddingLeft: '3px',
    paddingRight: '3px',
    zIndex: '1002',
    backgroundColor: 'white',
  },
  label: {
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  button: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
}));

const ChapterOrderForm = ({ unit, onClose, onEditChaptersOrder }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [orderedChapters, setOrderedChapters] = React.useState(
    unit?.chapters.map(chap => ({
      title: chap.title,
      id: chap.id,
    })),
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleSubmit = event => {
    event.preventDefault();

    const oldIdsOrder = unit?.chapters.map(chap => chap.id);
    const newIdsOrder = orderedChapters.map(chap => chap.id);

    if (JSON.stringify(oldIdsOrder) !== JSON.stringify(newIdsOrder)) {
      const unitAttributes = {
        id: unit.id,
        chapter_order: newIdsOrder,
      };

      onEditChaptersOrder(unitAttributes);
    }

    if (onClose) onClose();
  };

  const handleDragEnd = event => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const ids = orderedChapters.map(chap => chap.id);
      const oldIndex = ids.indexOf(active.id);
      const newIndex = ids.indexOf(over.id);
      setOrderedChapters(arrayMove(orderedChapters, oldIndex, newIndex));
    }
  };

  return (
    <Grid container>
      <Paper className={classes.paper}>
        <Grid item xs={12} className={classes.headingContainer}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center" gutterBottom style={{ paddingBottom: 8 }}>
              {t('APP.COURSES.CHAPTER.ORDER')}
            </Typography>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={orderedChapters} strategy={verticalListSortingStrategy}>
              {orderedChapters.map(item => (
                <SortableItem key={item.id} item={item} />
              ))}
            </SortableContext>
          </DndContext>
          <Grid className={classes.button}>
            <Button type="submit" variant="contained" color="primary">
              {t('SEND')}
            </Button>
          </Grid>
        </form>
      </Paper>
    </Grid>
  );
};

export default ChapterOrderForm;

type ItemProps = {
  item: Object,
};

const SortableItem = ({ item }: ItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: transform ? 'grabbing' : 'grab',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Paper elevation={2} style={{ padding: 16, margin: 16, marginLeft: 32, marginRight: 32 }}>
        {item.title}
      </Paper>
    </div>
  );
};
