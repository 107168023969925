import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@material-ui/core/styles';

import { getCdnUrl } from '../../../Services/ImageResizer';
import CourseForm from './CourseForm';

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
  },
  headerName: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '3',
    maxHeight: '3.8em',
    whiteSpace: 'normal',
  },
  helpers: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    // '&:first-child': {
    //   marginLeft: 0,
    // },
  },
  avatar: {
    marginRight: '-20px',
    width: 45,
    height: 45,
    fontSize: '14px',
    border: '2px solid #fff',
  },
  map: {
    padding: 0,
    height: 600,
    width: 800,
  },
  content: {
    paddingBottom: 0,
    paddingTop: 24,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  edit: {
    color: '#000000de',
    marginLeft: theme.spacing(2),
  },

  paper: {
    alignSelf: 'center',
  },
  more: {
    color: 'black',
    position: 'absolute',
    right: theme.spacing(2),
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  polygonBtn: {
    textTransform: 'none',
    width: '100%',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
  },
  mediaBtn: {
    textTransform: 'none',
    width: '100%',
    borderRadius: '4px 4px 0 0',
    height: 140,
    boxShadow: 'none',
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  body: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '4',
  },
}));

type Props = {
  course: Object,
  onEdit: Function,
  urlLink: String,
};

const CardCourseManage = ({ course, onEdit, urlLink }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [showEditCourse, setShowEditCourse] = React.useState(false);
  const visibilities = [{ label: 'APP.COURSES.VISIBILITY.PUBLIC', value: 'public' }];

  if (course.postable_type === 'Holding') {
    visibilities.push({ label: 'APP.COURSES.VISIBILITY.PRIVATE', value: 'private' });
  }

  return (
    <>
      <Grid>
        <Card className={classes.media}>
          <CardMedia
            component="img"
            className={classes.media}
            height="140"
            src={getCdnUrl(course.cover_image_url, 280, 140)}
            key={course.id}
          />
          <CardContent className={classes.content}>
            <Grid className={classes.grow}>
              <Typography variant="h4" component="h3" className={classes.headerName} align="left">
                {course.title}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                className={classes.headerName}
                align="left"
                style={{ paddingTop: 24 }}
              >
                {t('APP.COURSES.TEXT_FIELD.LABEL.DESCRIPTION')}
              </Typography>

              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                className={classes.body}
                dangerouslySetInnerHTML={{ __html: course.description }}
              />
              <Typography
                variant="h6"
                component="h6"
                className={classes.headerName}
                align="left"
                style={{ paddingTop: 16 }}
              >
                {t('APP.COURSES.TEXT_FIELD.LABEL.OBJECTIVES')}
              </Typography>

              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                dangerouslySetInnerHTML={{ __html: course.objectives }}
                className={classes.body}
              />
              <Grid className={classes.flex} style={{ paddingTop: 16 }}>
                <Typography variant="h6" component="h6" className={classes.headerName} align="left">
                  {t('APP.COURSES.TEXT_FIELD.LABEL.DURATION')} :
                </Typography>

                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ paddingLeft: 4 }}
                >
                  {course.duration}
                </Typography>
              </Grid>

              <Grid className={classes.flex} style={{ paddingTop: 16 }}>
                <Typography variant="h6" component="h6" className={classes.headerName} align="left">
                  {t('APP.COURSES.TEXT_FIELD.LABEL.VISIBILITY')} :
                </Typography>

                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ paddingLeft: 4 }}
                >
                  {t(`APP.COURSES.VISIBILITY.${course.visibility.toUpperCase()}`)}
                </Typography>
              </Grid>

              <Grid className={classes.flex} style={{ paddingTop: 16 }}>
                <Typography variant="h6" component="h6" className={classes.headerName} align="left">
                  {t('APP.COURSES.TEXT_FIELD.LABEL.STATE')} :
                </Typography>

                <Typography
                  variant="body2"
                  align="left"
                  color="textSecondary"
                  style={{ paddingLeft: 4 }}
                >
                  {t(`APP.COURSES.STATE.${course.state.toUpperCase()}`)}
                </Typography>
              </Grid>
            </Grid>
            <Grid style={{ paddingTop: 16, textAlign: 'center' }}>
              <Button
                variant="outlined"
                className={classes.button}
                color="primary"
                onClick={() => setShowEditCourse(true)}
                startIcon={<EditIcon />}
              >
                {t('EDIT')}
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {urlLink && (
        <Grid style={{ paddingTop: 24, textAlign: 'center' }}>
          <Button
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={() => history.push(urlLink)}
            startIcon={<ArrowBackIcon />}
          >
            {t('APP.COURSES.GO_BACK')}
          </Button>
        </Grid>
      )}
      <Dialog
        open={showEditCourse}
        onClose={() => setShowEditCourse(false)}
        fullWidth
        maxWidth="lg"
      >
        <Paper className={classes.paper} elevation={0}>
          <CourseForm
            onSubmit={onEdit}
            visibilities={visibilities}
            course={course}
            onClose={() => setShowEditCourse(false)}
          />
        </Paper>
      </Dialog>
    </>
  );
};
export default CardCourseManage;
