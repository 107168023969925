import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoursesActions from '../../../Redux/CoursesRedux';
import NoMatch404 from '../../NoMatch404';
import ChapterManage from '../../../Components/Shared/Courses/ChapterManage';

const HoldingChapterManage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const {
    holding_slug: holdingSlug,
    organisation_slug: orgaSlug,
    course_slug: courseSlug,
    chapter_id: chapterId,
  } = match.params;

  const chapter = useSelector(state => state.courses.chapter);
  const isFetching = useSelector(state => state.courses.isFetching.getHoldingCourseChapter);

  const errors = useSelector(state => state.courses.errors.getHoldingCourseChapter);

  React.useEffect(() => {
    if (holdingSlug && courseSlug && chapterId) {
      dispatch(CoursesActions.getHoldingCourseChapterRequest(holdingSlug, courseSlug, chapterId));
    }
  }, [holdingSlug, courseSlug, chapterId, dispatch]);

  const onEditChapter = (event, chapter, attachments) => {
    event.preventDefault();
    dispatch(
      CoursesActions.updateHoldingCourseChapterRequest(
        holdingSlug,
        courseSlug,
        chapter,
        attachments,
      ),
    );
  };

  return (
    <>
      {isFetching && <LinearProgress />}
      {!isFetching && chapter && `${chapterId}` === `${chapter.id}` && (
        <ChapterManage
          chapter={chapter}
          onEdit={onEditChapter}
          urlLink={`/${holdingSlug}/${orgaSlug}/formations/${courseSlug}`}
        />
      )}
      {!isFetching && errors?.status === 404 && <NoMatch404 />}
    </>
  );
};

export default HoldingChapterManage;
