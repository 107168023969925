import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import CoursesActions from '../../../Redux/CoursesRedux';
import NoMatch404 from '../../NoMatch404';
import ChapterManage from '../../../Components/Shared/Courses/ChapterManage';

const LeagueChapterManage = () => {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { league_id: leagueId, course_slug: courseSlug, chapter_id: chapterId } = match.params;

  const chapter = useSelector(state => state.courses.chapter);
  const isFetching = useSelector(state => state.courses.isFetching.getLeagueCourseChapter);

  const errors = useSelector(state => state.courses.errors.getLeagueCourseChapter);

  React.useEffect(() => {
    if (leagueId && courseSlug && chapterId) {
      dispatch(CoursesActions.getLeagueCourseChapterRequest(leagueId, courseSlug, chapterId));
    }
  }, [leagueId, courseSlug, chapterId, dispatch]);

  const onEditChapter = (event, chapter, attachments) => {
    event.preventDefault();
    dispatch(
      CoursesActions.updateLeagueCourseChapterRequest(leagueId, courseSlug, chapter, attachments),
    );
  };

  return (
    <>
      {isFetching && <LinearProgress />}
      {!isFetching && chapter && `${chapterId}` === `${chapter.id}` && (
        <ChapterManage
          chapter={chapter}
          onEdit={onEditChapter}
          urlLink={`/leagues/${leagueId}/formations/${courseSlug}`}
        />
      )}
      {!isFetching && errors?.status === 404 && <NoMatch404 />}
    </>
  );
};

export default LeagueChapterManage;
