import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import EditIcon from '@mui/icons-material/Edit';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import ChapterForm from './ChapterForm';

const useStyles = makeStyles(theme => ({
  media: {
    objectFit: 'cover',
  },
  headerName: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '1',
    maxHeight: '3.8em',
    whiteSpace: 'normal',
  },
  helpers: {
    display: 'flex',
    margin: theme.spacing(1, 0),
    // '&:first-child': {
    //   marginLeft: 0,
    // },
  },
  avatar: {
    marginRight: '-20px',
    width: 45,
    height: 45,
    fontSize: '14px',
    border: '2px solid #fff',
  },
  map: {
    padding: 0,
    height: 600,
    width: 800,
  },
  content: {
    paddingBottom: 0,
  },
  flex: {
    display: 'flex',
    flexDirection: 'row',
  },
  edit: {
    color: '#000000de',
    marginLeft: theme.spacing(2),
  },

  paper: {
    alignSelf: 'center',
  },
  more: {
    color: 'black',
    position: 'absolute',
    right: theme.spacing(2),
  },
  linkButton: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'inline',
    margin: 0,
    padding: 0,
    color: theme.palette.primary.main,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'none',
    },
  },
  polygonBtn: {
    textTransform: 'none',
    width: '100%',
    marginTop: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
  },
  mediaBtn: {
    textTransform: 'none',
    width: '100%',
    borderRadius: '4px 4px 0 0',
    height: 140,
    boxShadow: 'none',
  },
  button: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  body: {
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: '5',
  },
}));

type Props = {
  chapter: Object,
  onEdit: Function,
};

const CardChapterManage = ({ chapter, onEdit }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showEditChapter, setShowEditChapter] = React.useState(false);

  return (
    <>
      <Grid>
        <Card className={classes.media}>
          <CardContent className={classes.content}>
            <Grid className={classes.grow}>
              <Typography
                variant="h6"
                component="h6"
                className={classes.headerName}
                align="left"
                style={{ paddingTop: 16 }}
              >
                {t('APP.COURSES.TEXT_FIELD.LABEL.TITLE_CHAPTER')}
              </Typography>
              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                className={classes.headerName}
                gutterBottom
                style={{ paddingTop: 8 }}
              >
                {chapter.title}
              </Typography>
              <Typography
                variant="h6"
                component="h6"
                className={classes.headerName}
                align="left"
                style={{ paddingTop: 16 }}
              >
                {t('APP.COURSES.TEXT_FIELD.LABEL.ASSET_TYPE')}
              </Typography>

              <Typography
                variant="body2"
                align="left"
                color="textSecondary"
                className={classes.body}
              >
                {t(`APP.COURSES.ASSET.${chapter.asset_type?.toUpperCase()}`)}
              </Typography>
            </Grid>
            <Grid style={{ paddingTop: 24, textAlign: 'center' }}>
              <Button
                variant="outlined"
                className={classes.button}
                color="primary"
                onClick={() => setShowEditChapter(true)}
                startIcon={<EditIcon />}
              >
                {t('EDIT')}
              </Button>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Dialog open={showEditChapter} onClose={() => setShowEditChapter(false)} fullWidth>
        <Paper elevation={0}>
          <ChapterForm
            onSubmit={onEdit}
            chapter={chapter}
            onClose={() => setShowEditChapter(false)}
          />
        </Paper>
      </Dialog>
    </>
  );
};
export default CardChapterManage;
